@red: #F24B50;
@pink: #E20074;
@purple: #9C27B0;
@deep-purple: #673AB7;
@indigo: #3F51B5;
@blue: #2196F3;
@light-blue: #03A9F4;
@cyan: #00BCD4;
@teal: #009688;
@green: #4CAF50;
@light-green: #8BC34A;
@lime: #CDDC39;
@yellow: #FFEB3B;
@amber: #FFC107;
@orange: #FF9800;
@deep-orange: #FF5722;
@brown: #795548;
@grey: #9E9E9E;
@blue-grey: #607D8B;
@black: #000;
.make-theme(@color) {
    .sidebar .menu .list .ml-menu li.active a.toggled:not(.menu-toggle):before {
        color: @color;
    }
    .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
        background-color: @color;
    }
    .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
        background-color: fade(@color, 50%);
    }
    .mat-fab.mat-primary,
    .mat-mini-fab.mat-primary,
    .mat-raised-button.mat-primary {
        background-color: @color;
    }
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: @color;
    }
    .mat-radio-button.mat-accent .mat-radio-inner-circle {
        background-color: @color;
    }
    .mat-checkbox-checked.mat-accent:not(.mat-checkbox-disabled) .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-accent:not(.mat-checkbox-disabled) .mat-checkbox-background {
        background-color: @color;
    }
    .card .action .material-icons {
        color: @color  !important;
    }
    /* add theme-links to a parent element to indicate that all children anchors should use the current theme's color */
    .theme-links a {
        color: @color  !important;
    }
    .theme-active-link {
        .active a {
            color: @color  !important;
        }
        a:hover {
            color: @color  !important;
        }
        a:focus {
            color: @color  !important;
        }
    }
    .ngx-pagination .current {
        background-color: @color;
    }
    .btn-primary,
    .btn-primary:hover,
    .btn-primary:active,
    .btn-primary:focus {
        background-color: @color  !important;
    }
    // a.dropdown-toggle {
    //     color: @color !important;
    // }
    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: @color;
    }
    .mat-fab.mat-primary:not([disabled]),
    .mat-flat-button.mat-primary:not([disabled]),
    .mat-mini-fab.mat-primary:not([disabled]),
    .mat-raised-button.mat-primary:not([disabled]) {
        background-color: @color !important;
    }
    .mat-form-field-invalid .mat-input-element,
    .mat-warn .mat-input-element {
        caret-color: @color;
    }
    .form-group .form-line:after {
        border-bottom-color: @color;
    }
    .mat-form-field.mat-focused {
        .mat-form-field-label {
            color: @color;
        }
        .mat-form-field-ripple {
            background-color: @color;
        }
    }
}

.theme-red {
    .make-theme(@red)
}

.theme-pink {
    .make-theme(@pink)
}

.theme-purple {
    .make-theme(@purple)
}

.theme-deep-purple {
    .make-theme(@deep-purple)
}

.theme-indigo {
    .make-theme(@indigo)
}

.theme-blue {
    .make-theme(@blue)
}

.theme-light-blue {
    .make-theme(@light-blue)
}

.theme-cyan {
    .make-theme(@cyan)
}

.theme-teal {
    .make-theme(@teal)
}

.theme-green {
    .make-theme(@green)
}

.theme-light-green {
    .make-theme(@light-green)
}

.theme-lime {
    .make-theme(@lime)
}

.theme-yellow {
    .make-theme(@yellow)
}

.theme-amber {
    .make-theme(@amber)
}

.theme-orange {
    .make-theme(@orange)
}

.theme-deep-orange {
    .make-theme(@deep-orange)
}

.theme-brown {
    .make-theme(@brown)
}

.theme-grey {
    .make-theme(@grey)
}

.theme-blue-grey {
    .make-theme(@blue-grey)
}

.theme-black {
    .make-theme(@black)
}

/* General */

.clickable-item {
    cursor: pointer;
}

.text-bold {
    font-weight: bold;
}

/* Portlet */

.portlet.light>.portlet-title .inputs.inputs-full-width {
    display: block;
    float: none;
}

.famfamfam-flags {
    display: inline-block;
}

topbar-languageswitch {
    float: left;
    margin-top: 25px;
    margin-left: 10px;
    a {
        text-decoration: none;
        color: #fff;
        &:hover {
            text-decoration: none;
            color: #fff;
        }
    }
}

.swal-footer {
    text-align: center;
}

// mat-checkbox woraround
// https://github.com/angular/material2/issues/8798
.checkbox-wrapper {
    min-height: 50px;
    display: flex;
    >mat-checkbox {
        align-self: center;
    }
}

.abp-pagination-controls-wrapper {
    text-align: center;
}

.header-dropdown-mat-icon-button {
    bottom: 10px;
}

.action-button {
    top: -9px;
}

// force all material design form fields to be 100% wide
.mat-form-field {
    display: block !important;
}

.table-responsive{
    overflow: hidden;
}